import AssetMixin from '@/mixins/asset.mixin'
import ApiService from '@/services/api.service'
import { saveAs } from 'file-saver'

export default {
  mixins: [AssetMixin],
  data() {
    return {
      downloading: false
    }
  },
  methods: {
    downloadAssets(format, type = this.currentType.graphTypeName, id) {
      const Where = id
        ? [{ path: 'id', comparison: 'equal', value: [id] }]
        : this.filterToWhereExpression(this.filter)
      this.download(format, type, Where)
    },
    downloadHistories(format, type = this.currentType.graphTypeName, id) {
      const Where = id
        ? [{ path: 'id', comparison: 'equal', value: [id] }]
        : this.filterToWhereExpression(this.filter)
      this.download(format, `${type}History`, Where)
    },
    download(format, dataType, Where) {
      this.downloading = true

      ApiService.customRequest({
        method: 'post',
        url: '/export',
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob',
        data: {
          dataType,
          format,
          Search: this.search,
          Where
        }
      })
        .then((response) => {
          saveAs(
            new Blob([response.data]),
            `${dataType} ${this.formatDisplayDateTime(new Date())}.${
              format === 'Excel' ? 'xlsx' : 'csv'
            }`
          )
        })
        .catch((error) => {
          this.addError(null, `Download failed. ${error}`)
        })
        .finally(() => {
          this.downloading = false
        })
    }
  }
}
