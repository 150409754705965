import AssetMixin from '@/mixins/asset.mixin'
import GetUserHistories from '@/graphql/GetUserHistories.gql'
import { mapGetters } from 'vuex'

export default {
  props: ['tokenSubject'],
  mixins: [AssetMixin],
  data() {
    return {
      histories: [],
      assets: [],
      loading: true,
      headers: [
        { text: 'Date', value: 'createdAt' },
        { text: 'Event', value: 'changeType', sortable: false },
        { text: 'Item', value: 'item.id', sortable: false },
        { text: 'Fields', value: 'fields', sortable: false },
        { text: 'Old value', value: 'old', sortable: false },
        { text: 'New value', value: 'new', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters('graphql', ['assetTypes'])
  },
  apollo: {
    histories: {
      query: GetUserHistories,
      variables() {
        return {
          tokenSubject: this.tokenSubject
        }
      },
      update({ histories }) {
        return histories.items
      },
      result({ data, error }) {
        this.assets = _.uniq(_.get(data, 'histories.items', []).map(h => h.item)).filter(h => h)
        for (let item of data.histories.items) {
          item.path = this.getLink(item.item.id, item.item.__typename, item.item.name)
          if (item.changes) {
            for (let change of item.changes) {
              let field = _.replace(_.startCase(change.key), 'Id', '')
              change.field = field

              if (!!change.oldItem) {
                if (change.oldItem.__typename === 'IntraAccount') {
                  change.oldItem.path = 'show-user'
                } else {
                  change.oldItem.path = null
                }
              }

              if (!!change.newItem) {
                if (change.newItem.__typename === 'IntraAccount') {
                  change.newItem.path = 'show-user'
                } else {
                  change.newItem.path = null
                }
              }
            }
          }
        }
        if (error) {
          this.graphQLOnError(`Failed to get histories. ${error.toString()}`)
        }
      },
      skip() {
        return !this.tokenSubject
      }
    }
  },
  methods: {
    getLink(id, typename, name) {
      switch (typename) {
        case 'Computer':
          return { name: 'show-asset', params: { id, type: 'computers' } }
        case 'Location':
          return { name: 'list-locations', query: { search: name } }
        case 'Model':
          return { name: 'list-models', query: { search: name } }
        case 'Manufacturer':
        default:
          return ''
      }
    }
  }
}
