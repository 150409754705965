var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"user-history",attrs:{"column":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.histories,"sort-by":['createdAt'],"sort-desc":[true],"loading":_vm.$apollo.loading},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('v-simple-table',{staticClass:"fields-table"},[_c('tbody',_vm._l((_vm._.get(item, 'changes', [0])),function(change,i){return _c('tr',{key:i},[(i === 0)?_c('td',[_vm._v(_vm._s(_vm.formatDisplayDateTime(item.createdAt)))]):_c('td')])}),0)])]}},{key:"item.changeType",fn:function(ref){
var item = ref.item;
return [_c('v-simple-table',{staticClass:"fields-table"},[_c('tbody',_vm._l((_vm._.get(item, 'changes', [0])),function(change,i){return _c('tr',{key:i},[(i === 0)?_c('td',[_vm._v(_vm._s(item.changeType))]):_c('td')])}),0)])]}},{key:"item.item.id",fn:function(ref){
var item = ref.item;
return [_c('v-simple-table',{staticClass:"fields-table"},[_c('tbody',_vm._l((_vm._.get(item, 'changes', [0])),function(change,i){return _c('tr',{key:i},[(i === 0)?_c('td',[(item.path)?_c('router-link',{attrs:{"to":item.path}},[_vm._v(" "+_vm._s(item.item.name)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.item.name)+" ")])],1):_c('td')])}),0)])]}},{key:"item.fields",fn:function(ref){
var item = ref.item;
return [_c('v-simple-table',{staticClass:"fields-table"},[_c('tbody',_vm._l((item.changes),function(change,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(change.field))])])}),0)])]}},{key:"item.old",fn:function(ref){
var item = ref.item;
return [_c('v-simple-table',{staticClass:"fields-table"},[_c('tbody',_vm._l((item.changes),function(change,i){return _c('tr',{key:i},[(change.old === null)?_c('td',{staticClass:"caption"},[_c('i',[_vm._v("null")])]):_c('td',[(change.oldItem && change.oldItem.path)?_c('router-link',{attrs:{"to":{ name: change.oldItem.path, params: { id: change.old } }}},[_vm._v(_vm._s(change.oldItem.name))]):_c('span',[_vm._v(_vm._s(!!change.oldItem && change.oldItem.name || change.old))])],1)])}),0)])]}},{key:"item.new",fn:function(ref){
var item = ref.item;
return [_c('v-simple-table',{staticClass:"fields-table"},[_c('tbody',_vm._l((item.changes),function(change,i){return _c('tr',{key:i},[(change.new === null)?_c('td',{staticClass:"caption"},[_c('i',[_vm._v("null")])]):_c('td',[(change.newItem && change.newItem.path)?_c('router-link',{attrs:{"to":{ name: change.newItem.path, params: { id: change.new } }}},[_vm._v(_vm._s(change.newItem.name))]):_c('span',[_vm._v(_vm._s(!!change.newItem && change.newItem.name || change.new))])],1)])}),0)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }