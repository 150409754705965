export default {
  props: ['items'],
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Serial Number', value: 'serialNumber' },
        { text: 'Model Name', value: 'modelName' },
        { text: 'Asset Type', value: 'assetType' },
        { text: 'Location', value: 'location' },
        { text: 'Assigned', value: 'assigned' },
        { text: 'Unassigned', value: 'unassigned' }
      ]
    }
  }
}
