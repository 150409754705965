import FullScreenLoader from '@/components/FullScreenLoader/FullScreenLoader.vue'
import UserHistory from '@/components/User/UserHistory/UserHistory.vue'
import UserAssets from '@/components/User/UserAssets/UserAssets.vue'
import ExportMixin from '@/mixins/export.mixin'

export default {
  props: ['id'],
  mixins: [ExportMixin],
  components: {
    FullScreenLoader,
    UserHistory,
    UserAssets
  },
  data() {
    return {
      user: {},
      assets: [],
      rolesHeaders: [{ text: 'Name', value: 'name' }]
    }
  },
  computed: {
    roles() {
      return _.get(this.user, 'roles', [])
    },
    cards() {
      const d = key => _.get(this.user, key, '') || ''
      return [
        {
          title: 'Basic info',
          items: [
            { title: 'Employee ID', value: d('employeeId') },
            { title: 'SamAccountName', value: d('samAccountName') },
            { title: 'Deleted Externally', value: this.user.deletedExternally }
          ]
        }
      ]
    },
    breadcrumbItems() {
      let text
      let name = _.get(this.user, 'name', '')

      if (this.$route.name === 'show-user') {
        text = `${name}`
      } else if (this.$route.name === 'edit-user') {
        text = `Edit ${name}`
      }
      return [
        { text: 'Users', disabled: false, to: { name: 'list-users' }, exact: true },
        {
          text
        }
      ]
    },
    tokenSubject() {
      // TODO: use UPN instead of adding @rakuten.com to samAccountName
      const samAccountName = _.get(this.user, 'samAccountName')
      if (samAccountName) {
        return `${samAccountName}@rakuten.com`
      }
      return ''
    }
  },
  methods: {
    downloadUserAssets(format) {
      const Where = [{ path: 'id', comparison: 'equal', value: [this.user.id] }]
      this.download(format, 'UserAssets', Where)
    },
    downloadUserHistories(format) {
      const Where = [{ path: 'tokenSubject', comparison: 'equal', value: [this.tokenSubject] }]
      this.download(format, 'UserHistory', Where)
    }
  },
  apollo: {
    intraAccount: {
      query() {
        const allFields = [
          'id',
          'name',
          'employeeId',
          'samAccountName',
          'deletedExternally',
          {
            name: 'historicalAssets',
            type: 'HistoricalAsset',
            fields: ['itemId', 'assetType', 'name', 'serialNumber', 'modelName', 'office', 'assigned', 'unassigned']
          },
          { name: 'roles', type: 'Role', fields: ['name'] }
        ]
        const fields = this.getFieldsGql('read', 'IntraAccount', allFields)
        return this.$gql`
          query intraAccount($id: ID!) {
            intraAccount(id: $id) {
              ${fields}
            }
          }
        `
      },
      variables() {
        return { id: this.id }
      },
      skip() {
        return !this.id || !this.ability.can('read', 'IntraAccount')
      },
      result({ data, error }) {
        if (data && data.intraAccount) {
          this.user = data.intraAccount
          this.assets = _.map(data.intraAccount.historicalAssets, ha => ({
            ...ha,
            ...{
              path: `/assets/${this.getRouteName(ha.assetType)}/${ha.itemId}`,
              assigned: this.formatDisplayDateTime(ha.assigned),
              unassigned: this.formatDisplayDateTime(ha.unassigned)
            }
          }))

        } else if (error) {
          this.graphQLOnError(`Failed to get user from server. ${error.toString()}`)
          if (error.toString().includes('Not found')) {
            this.renderError(404)
          }
        }
      }
    }
  },
  created() {
    if (!this.ability.can('read', 'IntraAccount')) {
      this.$router.push('/')
    } else {
      if (_.isNaN(parseInt(this.id)) || parseInt(this.id) > 2147483647) {
        this.renderError(404)
      }
    }
  }
}
